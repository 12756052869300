import ReactDOM from "react-dom";
import { MenuItem } from "./MenuItem";

interface SideMenuProps {
    onCloseMenu: () => void;
    onNewChat: () => void;
}

export const SideMenu = ({
    onCloseMenu,
    onNewChat
}: SideMenuProps) => {
    return ReactDOM.createPortal(
        <>
            <div id="headlessui-portal-root">
                <div data-headlessui-portal="">
                    <button
                        type="button"
                        aria-hidden="true"
                        style={{ position: "fixed", top: "1px", left: "1px", width: "1px", height: "0px", padding: "0px", margin: "-1px", overflow: "hidden", clip: "rect(0px, 0px, 0px, 0px)", whiteSpace: "nowrap", borderWidth: "0px" }}
                    ></button><div>
                        <div className="relative z-40 md:hidden" id="headlessui-dialog-:rk:" role="dialog" aria-modal="true" data-headlessui-state="open">
                            <div className="fixed inset-0 bg-gray-600 bg-opacity-75 opacity-100"></div>
                            <div className="fixed inset-0 z-40 flex">
                                <div className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-900 translate-x-0" id="headlessui-dialog-panel-:rl:" data-headlessui-state="open">
                                    <div className="absolute top-0 right-0 -mr-12 pt-2 opacity-100">
                                        <button
                                            type="button" className="ml-1 flex h-10 w-10 items-center justify-center focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" tabIndex={0}
                                            onClick={onCloseMenu}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <svg stroke="currentColor" fill="none" stroke-width="1.5" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" className="h-6 w-6 text-white" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                                <line x1="6" y1="6" x2="18" y2="18"></line>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="scrollbar-trigger flex h-full w-full flex-1 items-start border-white/20">
                                        <nav className="flex h-full flex-1 flex-col space-y-1 p-2">
                                            <button
                                                onClick={onNewChat}
                                                className="flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-500/10 transition-colors duration-200 text-white cursor-pointer text-sm mb-2 flex-shrink-0 border border-white/20"
                                            >
                                                <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" className="h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                    <line x1="12" y1="5" x2="12" y2="19"></line>
                                                    <line x1="5" y1="12" x2="19" y2="12"></line>
                                                </svg>
                                                New chat
                                            </button>
                                            <div className="flex-col flex-1 overflow-y-auto border-b border-white/20 -mr-2">
                                                <div className="flex flex-col gap-2 text-gray-100 text-sm h-full justify-center items-center">
                                                </div>
                                            </div>
                                            <div>
                                                <MenuItem icon="las la-user-plus" text="Upgrade to Plus" />
                                                <MenuItem icon="las la-bullhorn" text="Contact us" />
                                            </div>
                                        </nav>
                                    </div>
                                </div>
                                <div className="w-14 flex-shrink-0"></div>
                            </div>
                        </div>
                    </div>
                    <button
                        type="button"
                        aria-hidden="true"
                        style={{ position: "fixed", top: "1px", left: "1px", width: "1px", height: "0px", padding: "0px", margin: "-1px", overflow: "hidden", clip: "rect(0px, 0px, 0px, 0px)", whiteSpace: "nowrap", borderWidth: "0px" }}
                    ></button>
                </div>
            </div>
        </>,
        document.querySelector('#root')!
    );
};
