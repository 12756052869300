import { useEffect, useState } from 'react';
import { ReactComponent as SendButton } from '../images/arrow-right-send.svg';
import { Message, Owner } from '../types';

interface StaticMessageProps {
    text: string;
    url?: string;
    owner: Owner;
    showCursor?: boolean;
}

interface ChatMessageProps extends Omit<StaticMessageProps, 'url' | 'owner'> {
    onDone: () => void;
}

interface ChatBoxProps {
    conversation: Message[];
    onDone: () => void;
}

const StaticMessage = ({
    text,
    url,
    owner,
    showCursor
}: StaticMessageProps) => {
    const color = (owner === 'GusGPT') ? '#22c55e' : '#fb923c'

    return (
        <>
            <div className="w-full border-b border-black/10 dark:border-gray-900/50 text-gray-800 dark:text-gray-100 group dark:bg-gray-800">
                <div className="text-base gap-4 md:gap-6 m-auto md:max-w-2xl lg:max-w-2xl xl:max-w-3xl p-4 md:py-6 flex lg:px-0">
                    <div className="w-[30px] flex flex-col relative items-end">
                        <div className="relative h-[30px] w-[30px] p-1 rounded-sm text-white flex items-center justify-center" style={{ backgroundColor: color }}>
                            <SendButton className="h-4 w-4 rotate-90" />
                        </div>
                    </div>
                    <div className="relative flex w-[calc(100%-50px)] md:flex-col lg:w-[calc(100%-115px)]">
                        <div className="flex flex-grow flex-col gap-3">
                            <div className="min-h-[20px] flex flex-col items-start gap-4 whitespace-pre-wrap">
                                <div>
                                    <p className="inline">{text}</p>
                                    <span className="px-[4px] py-[1px] bg-gray-400" style={{ opacity: showCursor ? 1 : 0 }}></span>
                                </div>
                                {!!url && (
                                    <div>
                                        <img alt="" className="max-h-96" src={url} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const ChatMessage = ({
    text,
    onDone
}: ChatMessageProps) => {
    const [showCursor, setShowCursor] = useState(true);
    const [displayText, setDisplayText] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            setShowCursor(prevShowCursor => !prevShowCursor);
        }, 500);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        let index = 0;

        const interval = setInterval(() => {
            if (text.length === 0) {
                return;
            }

            if (index < text.length) {
                setDisplayText(prevText => text.substring(0, index));

                index++;

                return;
            }

            onDone();
        }, 35);

        return () => clearInterval(interval);
    }, [text, onDone]);

    return (
        <>
            <StaticMessage text={displayText} owner="GusGPT" showCursor={showCursor} />
        </>
    );
};

export const ChatBox = ({
    conversation,
    onDone
}: ChatBoxProps) => {
    return (
        <>
            {conversation.map((message, index) => {
                if (message.owner === 'User' || message.done) {
                    return (
                        <StaticMessage
                            key={index}
                            {...message}
                        />
                    );
                }

                return (
                    <ChatMessage
                        key={index}
                        {...message}
                        onDone={onDone}
                    />
                );
            })}
            <div className="w-full h-32 md:h-48 flex-shrink-0"></div>
        </>
    );
};
