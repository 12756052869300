export const Intro = () => {
    return (
        <>
            <div className="flex flex-col items-center text-sm h-full dark:bg-gray-800">
                <div className="text-gray-800 w-full md:max-w-2xl lg:max-w-3xl md:h-full md:flex md:flex-col px-6 dark:text-gray-100">
                    <h1 className="text-4xl font-semibold text-center mt-6 sm:mt-[20vh] ml-auto mr-auto mb-10 sm:mb-16 flex gap-2 items-center justify-center">
                        GusGPT
                    </h1>
                    <div className="md:flex items-start text-center gap-3">
                        <div className="flex flex-col mb-8 md:mb-auto gap-3.5 flex-1">
                            <h2 className="flex gap-3 items-center m-auto text-lg font-normal md:flex-col md:gap-2">
                                <i className="w-6 h-6 text-2xl las la-sun"></i>
                                Examples
                            </h2>
                            <ul className="flex flex-col gap-3.5 w-full sm:max-w-md m-auto">
                                <button className="w-full bg-gray-50 dark:bg-white/5 p-3 rounded-md hover:bg-gray-200 dark:hover:bg-gray-900">
                                    "Explain quantum computing in super complex terms"
                                    <i className="ml-1 las la-arrow-right"></i>
                                </button>
                                <button className="w-full bg-gray-50 dark:bg-white/5 p-3 rounded-md hover:bg-gray-200 dark:hover:bg-gray-900">
                                    "Got any excuses to not celebrate a 10 year old's birthday?"
                                    <i className="ml-1 las la-arrow-right"></i>
                                </button>
                                <button className="w-full bg-gray-50 dark:bg-white/5 p-3 rounded-md hover:bg-gray-200 dark:hover:bg-gray-900">
                                    "How to not suck at Javascript development?"
                                    <i className="ml-1 las la-arrow-right"></i>
                                </button>
                            </ul>
                        </div>
                        <div className="flex flex-col mb-8 md:mb-auto gap-3.5 flex-1">
                            <h2 className="flex gap-3 items-center m-auto text-lg font-normal md:flex-col md:gap-2">
                                <i className="w-6 h-6 text-2xl las la-plug"></i>
                                Capabilities
                            </h2>
                            <ul className="flex flex-col gap-3.5 w-full sm:max-w-md m-auto">
                                <li className="w-full bg-gray-50 dark:bg-white/5 p-3 rounded-md">
                                    Do not remember anything you said, even a sec ago
                                </li>
                                <li className="w-full bg-gray-50 dark:bg-white/5 p-3 rounded-md">
                                    Allow user correct themselves, and hates comments
                                </li>
                                <li className="w-full bg-gray-50 dark:bg-white/5 p-3 rounded-md">
                                    Trained to ignore you if you're being stupid
                                </li>
                            </ul>
                        </div>
                        <div className="flex flex-col mb-8 md:mb-auto gap-3.5 flex-1">
                            <h2 className="flex gap-3 items-center m-auto text-lg font-normal md:flex-col md:gap-2">
                                <i className="w-6 h-6 text-2xl las la-exclamation-triangle"></i>
                                Limitations
                            </h2>
                            <ul className="flex flex-col gap-3.5 w-full sm:max-w-md m-auto">
                                <li className="w-full bg-gray-50 dark:bg-white/5 p-3 rounded-md">
                                    Will always generate incorrect information, duh!
                                </li>
                                <li className="w-full bg-gray-50 dark:bg-white/5 p-3 rounded-md">
                                    May, or may not, produce harmful instructions or content
                                </li>
                                <li className="w-full bg-gray-50 dark:bg-white/5 p-3 rounded-md">
                                    Limited knowledge of world. Period.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="w-full h-32 md:h-48 flex-shrink-0"></div>
            </div>
        </>
    );
};
