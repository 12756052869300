import { useEffect, useState } from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';
import { ChatBox } from '../components/ChatBox';
import { Intro } from '../components/Intro';
import { Menu } from '../components/Menu';
import { ReactComponent as SendButton } from '../images/arrow-right-send.svg';
import { Message } from '../types';
import { useConversations } from '../hooks/useConversations';
import { SideMenu } from './SideMenu';

enum ViewState {
    Intro = 'Intro',
    ChatBox = 'ChatBox'
}

export const Master = () => {
    const [view, setView] = useState<ViewState>(ViewState.Intro);
    const [text, setText] = useState('');
    const [conversation, setConversation] = useState<Message[]>([]);
    const [processing, setProcessing] = useState(false);
    const [showSideMenu, setShowSideMenu] = useState(false);

    const { newMessage, isFetchingMsg, refetchMsg } = useConversations();

    const addMessage = () => {
        const userMessage: Message = { text: text, owner: 'User', done: true };
        const gptMessage: Message = { text: '', owner: 'GusGPT', done: false };

        setConversation([...conversation, userMessage, gptMessage]);
        setText('');
        refetchMsg();
        setView(ViewState.ChatBox);
    };

    const onDone = () => {
        const temp = [...conversation].pop();

        if (temp) {
            temp.done = true;
            setProcessing(false);
            setConversation([...conversation]);
        }
    };

    const onNewChat = () => {
        setProcessing(false);
        setText('');
        setConversation([]);
        setView(ViewState.Intro);
    };

    useEffect(() => {
        if (newMessage) {
            setProcessing(true);
            setConversation(c => [...c.slice(0, -1), newMessage]);
        }
    }, [newMessage]);

    return (
        <>
            <div className="overflow-hidden w-full h-full relative">
                <div className="flex h-full flex-1 flex-col md:pl-[260px]">
                    <div className="sticky top-0 z-10 flex items-center border-b border-white/20 bg-gray-800 pl-1 pt-1 text-gray-200 sm:pl-3 md:hidden">
                        <button
                            type="button" className="open-menu -ml-0.5 -mt-0.5 inline-flex h-10 w-10 items-center justify-center rounded-md hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white dark:hover:text-white"
                            onClick={() => setShowSideMenu(v => !v)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <svg stroke="currentColor" fill="none" strokeWidth="1.5" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <line x1="3" y1="12" x2="21" y2="12"></line>
                                <line x1="3" y1="6" x2="21" y2="6"></line>
                                <line x1="3" y1="18" x2="21" y2="18"></line>
                            </svg>
                        </button>
                        <h1 className="flex-1 text-center text-base font-normal">New chat</h1>
                        <button type="button" className="px-3 new-cat" onClick={onNewChat}>
                            <svg stroke="currentColor" fill="none" strokeWidth="1.5" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="h-6 w-6" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <line x1="12" y1="5" x2="12" y2="19"></line>
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                            </svg>
                        </button>
                    </div>
                    <main className="relative h-full w-full transition-width flex flex-col overflow-hidden items-stretch flex-1">
                        <div className="flex-1 overflow-hidden">
                            <ScrollToBottom className="h-full dark:bg-gray-800">
                                {view === ViewState.Intro && <Intro />}
                                {view === ViewState.ChatBox && <ChatBox conversation={conversation} onDone={onDone} />}
                            </ScrollToBottom>
                        </div>
                        <div
                            className="absolute bottom-0 left-0 w-full border-t md:border-t-0 dark:border-white/20 md:border-transparent md:dark:border-transparent md:bg-vert-light-gradient bg-white dark:bg-gray-800 md:!bg-transparent dark:md:bg-vert-dark-gradient pt-2"
                            style={{ backgroundImage: "linear-gradient(180deg,rgba(53,55,64,0),#1f2a38 58.85%)" }}
                        >
                            <div className="stretch mx-2 flex flex-row gap-3 pt-2 last:mb-2 md:last:mb-6 lg:mx-auto lg:max-w-3xl lg:pt-6">
                                <div className="relative flex h-full flex-1 md:flex-col">
                                    <div className="ml-1 mt-1.5 md:w-full md:m-auto md:flex md:mb-2 gap-2 justify-center"></div>
                                    <div className="flex flex-col w-full py-2 flex-grow md:py-3 md:pl-4 relative border border-black/10 bg-white dark:border-gray-900/50 dark:text-white dark:bg-gray-700 rounded-md shadow-[0_0_10px_rgba(0,0,0,0.10)] dark:shadow-[0_0_15px_rgba(0,0,0,0.10)]">
                                        <textarea
                                            tabIndex={0}
                                            rows={1}
                                            placeholder={isFetchingMsg || processing ? "" : "Send a message..."}
                                            value={text}
                                            onChange={(event) => setText(event.target.value)}
                                            disabled={isFetchingMsg || processing}
                                            onKeyDown={(event) => {
                                                if (event.keyCode === 13 && !event.shiftKey) {
                                                    addMessage();
                                                }
                                            }}
                                            className="m-0 w-full resize-none border-0 bg-transparent p-0 pl-2 pr-7 border-transparent focus:border-transparent focus:ring-0 focus-visible:border-transparent focus-visible:ring-0 focus:outline-none focus-visible:outline-none dark:bg-transparent md:pl-0 max-h-[200px] h-[24px] overflow-y-hidden">
                                        </textarea>
                                        <button
                                            disabled={!text || isFetchingMsg || processing}
                                            onClick={addMessage}
                                            className="absolute p-1 rounded-md text-gray-400 dark:disabled:text-gray-600 bottom-1.5 right-1 md:bottom-2.5 md:right-2 hover:bg-gray-100 dark:hover:text-gray-400 dark:hover:bg-gray-900 disabled:hover:bg-transparent dark:disabled:hover:bg-transparent"
                                        >
                                            <SendButton className="h-4 w-4 rotate-90" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="px-3 pt-2 pb-3 text-center text-xs text-black/50 dark:text-white/50 md:px-4 md:pt-3 md:pb-6">
                                <span className="underline">GusGPT Apr 1 Version</span>.
                                Free unless you want to pay. Our goal is to make you laugh a little bit. Or not.
                            </div>
                        </div>
                    </main>
                </div>
                <div className="dark hidden bg-gray-900 md:fixed md:inset-y-0 md:flex md:w-[260px] md:flex-col">
                    <Menu onNewChat={onNewChat} />
                </div>
            </div>
            {showSideMenu && <SideMenu onCloseMenu={() => setShowSideMenu(false)} onNewChat={onNewChat} />}
        </>
    );
};
