import { MenuItem } from "./MenuItem";

interface MenuProps {
    onNewChat: () => void;
}

export const Menu = ({
    onNewChat
}: MenuProps) => {
    return (
        <>
            <div className="flex h-full min-h-0 flex-col ">
                <div className="scrollbar-trigger flex h-full w-full flex-1 items-start border-white/20">
                    <nav className="flex h-full flex-1 flex-col space-y-1 p-2">
                        <button
                            className="new-cat flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-500/10 transition-colors duration-200 text-white cursor-pointer text-sm mb-2 flex-shrink-0 border border-white/20"
                            onClick={onNewChat}
                        >
                            <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                <line x1="12" y1="5" x2="12" y2="19"></line>
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                            </svg>
                            New chat
                        </button>
                        <div className="flex-col flex-1 overflow-y-auto border-b border-white/20">
                            <div className="flex flex-col gap-2 text-gray-100 text-sm h-full justify-center items-center">
                            </div>
                        </div>
                        <div>
                            <MenuItem icon="las la-user-plus" text="Upgrade to Plus" />
                            <MenuItem icon="las la-bullhorn" text="Contact us" />
                        </div>
                    </nav>
                </div>
            </div>
        </>
    );
};
