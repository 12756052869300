interface MenuItemProps {
    icon: string;
    text: string;
}

export const MenuItem = ({
    icon,
    text
}: MenuItemProps) => {
    return (
        <>
            <a className="flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-500/10 transition-colors duration-200 text-white cursor-pointer text-sm">
                <i className={`text-xl w-6 h-6 ${icon}`}></i>
                {text}
            </a>
        </>
    );
};