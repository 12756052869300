import { useQuery } from "@tanstack/react-query";
import axios from 'axios';
import { Message } from "../types";

const apiNinjasKey: string = process.env.REACT_APP_API_NINJAS_KEY!;

const random = (min: number, max: number) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

const sleep = (): Promise<void> =>
    new Promise((resolve) => setTimeout(resolve, random(1, 3) * 1000));

const topics = [
    {
        topic: 'memes',
        get: async () => {
            const { data } = await axios.get<{ url: string; }>('https://meme-api.com/gimme/memes');

            return {
                text: "I'm not too sure about that, but check out this meme I got, ese!",
                url: data.url,
                owner: 'GusGPT',
                done: false
            } as Message;
        }
    },
    {
        topic: 'dad jokes',
        get: async () => {
            const { data } = await axios.get<{ joke: string; }[]>('https://api.api-ninjas.com/v1/dadjokes', {
                headers: {
                    'x-api-key': apiNinjasKey
                }
            });

            return {
                text: `I ain't no guru on that particular craft, but I do have a dad joke that'll probably have you chuckling!\n\n${data[0].joke}`,
                owner: 'GusGPT',
                done: false
            } as Message;
        }
    },
    {
        topic: 'chuck norris',
        get: async () => {
            const { data } = await axios.get<{ joke: string; }>('https://api.api-ninjas.com/v1/chucknorris', {
                headers: {
                    'x-api-key': apiNinjasKey
                }
            });

            return {
                text: `Changing topics a bit, what do you think about Chuck Norris? You know what they say about him, right?\n\n${data.joke}`,
                owner: 'GusGPT',
                done: false
            } as Message;
        }
    }
];

export const useConversations = () => {
    const {
        isFetching,
        refetch,
        data
    } = useQuery(['memes'], async () => {

        const randomTopicNumber = random(0, topics.length - 1);

        await sleep();

        return await topics[randomTopicNumber].get();
    }, {
        enabled: false
    });

    return {
        isFetchingMsg: isFetching,
        refetchMsg: refetch,
        newMessage: data
    };
};
