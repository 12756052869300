import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Master } from './components/Master';
import './scss/app.scss';

export const App = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                refetchOnReconnect: false,
                retry: false
            }
        }
    });

    return (
        <>
            <QueryClientProvider client={queryClient}>
                <Master />
            </QueryClientProvider>
        </>
    );
};
